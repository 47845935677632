@import '~app/mixins';

.dashboard {
  width: 100%;
  // background-color: #e4e7ee;

  .header {
    .nameEdit {
      display: flex;
      align-items: center;
    }

    .nameInput {
      color: $color-blue-dark;
      font-size: 24px;
      line-height: 32px;
      border: none;
      background: transparent;
      max-width: 700px;
    }
  }

  .content {
    background-color: #e5e7ee;
    .panel {
      border: 2px solid $color-grey-lighter;
      border-radius: 10px;
      background-color: $color-white;
      padding: 25px 30px;
      margin-bottom: 15px;
    }

    .topBar {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .campaignInfo {
        display: flex;
        align-items: center;

        .logo {
          $size: 50px;

          background: $color-grey-lighter;
          width: $size;
          height: $size;
          margin-right: 25px;
        }

        .title {
          color: $color-grey-light;
          font-size: 12px;
          line-height: 14px;

          span {
            padding-left: 5px;
          }
        }

        .value {
          color: $color-grey;
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
        }
      }

      .websiteInfo {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .website {
          display: flex;
          align-items: center;
        }

        button {
          margin-top: 6px;
        }
      }
    }

    .totalBar {
      display: flex;
      justify-content: center;
      padding: 20px 0;

      .total {
        width: 215px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .top {
          display: flex;
          align-items: center;
          margin-bottom: 5px;

          .icon {
            height: 25px;
            width: 23px;
            margin-right: 5px;

            use {
              fill: $color-grey;
            }
          }

          .caption {
            color: $color-grey-light;
            font-size: 13px;
          }
        }

        .value {
          color: $color-grey;
          font-size: 36px;
          font-weight: bold;
          height: 68px;
          min-width: 100px;
          border: 2px solid $color-grey-lighter;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 6px;
        }
      }
    }

    .activities {
      margin-top: 100px;
    }
  }

  .items {
    $offset: 15px;

    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .card {
      width: calc(33.333% - #{$offset*2});
      border: 2px solid $color-grey-lighter;
      background-color: $color-white;
      padding: 15px 20px;
      margin-right: $offset;
      display: block;
      border-radius: 5px;

      &:nth-child(3n + 3) {
        margin-right: 0;
      }

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }

      &.hasError {
        background: #f2dede;
      }
    }

    .type {
      color: $color-grey-1;
      font-size: 14px;
      font-style: italic;
      line-height: 18px;
      margin-top: 5px;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
    }

    .name {
      color: $color-blue-dark;
      font-size: 16px;
      line-height: 18px;
    }

    .lastEdit {
      color: $color-grey-1;
      font-size: 14px;
      line-height: 18px;
    }

    .status {
      margin-left: 20px;
    }

    .preview {
      background: $color-dirty-white;
      height: 200px;
      width: 70%;
      position: relative;
      overflow: hidden;
      text-align: center;

      img {
        height: 100%;
      }
    }

    .statusPublished,
    .statusUnpublished {
      padding: 3px 10px;
      border: 1px solid #979797;
      border-radius: 100px;
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
    }

    .statusPublished {
      background-color: $color-grey;
      color: $color-white;
    }

    .statusUnpublished {
      background-color: $color-white;
      color: $color-grey;
    }

    .bottom {
      margin-top: 20px;
      display: flex;
    }

    .info {
      padding-left: 25px;
    }

    .caption {
      color: $color-grey;
      font-size: 11px;
      font-style: italic;
      line-height: 13px;
    }

    .value {
      color: $color-grey;
      font-size: 28px;
      font-weight: 900;
      line-height: 37px;
    }

    .row {
      margin-bottom: 25px;
    }

    .emptyCard {
      border: 2px solid $color-grey-lighter;
      background-color: $color-white;
      text-align: center;
      padding: 15px 20px;
      width: 325px;
      margin: 10px 0;

      .btn {
        min-width: 135px;
      }

      .caption {
        color: $color-blue-dark;
        font-style: normal;
        font-size: 16px;
        line-height: 18px;
      }

      .icon {
        $size: 40px;

        width: $size;
        height: $size;
        margin: 25px 0;

        use {
          fill: $color-grey-light;
        }
      }

      .text {
        color: $color-grey;
        font-size: 11px;
        font-style: italic;
        line-height: 13px;
        margin-bottom: 20px;
        min-height: 30px;
      }

      .comingSoon {
        font-weight: bold;
        color: $color-grey;
        margin: -10px 0 10px;
      }
    }
  }
}

.activities {
  margin-top: 20px;
  color: $color-grey;
  display: flex;
  justify-content: center;

  .activitiesInner {
    display: flex;

    .activity {
      margin: 0 15px;
      width: 200px;

      button {
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }
}

.addActivity {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;

  > div {
    + div {
      margin-left: 25px;
    }
  }
}

.campaignCreated {
  .caption {
    color: $color-grey;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    margin: 20px 0;
  }

  .buttons {
    text-align: center;
  }

  .button {
    width: 280px;
    margin: 0 auto 15px;
  }
}
